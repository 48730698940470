import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import CallList from '../components/calls';
import PageHeader from '../components/PageHeader';

const Container = styled(Stack)`
  background-color: #e5e5e5;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding: 0px;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 0px;
  }
`;

const Calls = () => {
  return (
    <Container>
      <PageHeader title={"Call Logs"} />

      <div className="mt-7 gap-5 flex flex-col xl:flex-row gap-2 p-5">
        <div className="bg-white w-full rounded-xl ">
          <CallList />
        </div>
      </div>
    </Container>
  );
};

export default Calls;
