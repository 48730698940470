import { createContext, useState, useContext, useEffect } from 'react';

const GlobalState = createContext();

const defaultValues = {
  isLoggedIn: false,
  user: {
    usersProfileId: null,
    email: '',
    id: null,
    usersTypeId: 1,
  },
  notification: {
    title: '',
    type: 'info',
    message: '',
  },
  usersSettings: {},
  skillsOptions: [],
  appointmentModal: {},
};

export const useProvideGlobalState = () => {
  const [storeData, setStoreData] = useState({ ...defaultValues });

  //update state using value from localStorage
  useEffect(() => {
    const user = loadFromLocalStorage('user');
    const isLoggedIn = loadFromLocalStorage('isLoggedIn');

    if (user && user.email !== '') {
      storeData.user = user;
      storeData.usersSettings = user.usersSettings;

      storeData.isLoggedIn = isLoggedIn || false;
      setStoreData({ ...storeData });
    }

    if (isLoggedIn) {
      setStoreData({ ...storeData, isLoggedIn: isLoggedIn || false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    // reset
    storeData.isLoggedIn = false;
    storeData.user = defaultValues;
    setStoreData({ ...storeData });
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isLoggedIn');
    window.location.reload();
  };

  const saveToLocalStorage = (itemKey, item) => {
    localStorage.setItem(itemKey, JSON.stringify(item));
  };

  const loadFromLocalStorage = (itemKey) => {
    return JSON.parse(localStorage.getItem(itemKey)) || null;
  };

  const updateStoreData = (prop, value) => {
    if (prop === 'isLoggedIn') {
      saveToLocalStorage(prop, value);
    }
    setStoreData({ ...storeData, [prop]: value });
  };

  const updateUserData = (newUserValue = {}) => {
    const user = {
      ...storeData.user,
      ...newUserValue,
    };
    storeData.user = user;
    setStoreData({ ...storeData });
    saveToLocalStorage('user', user);
  };

  const updateSkillsOptions = (newValue = []) => {
    setStoreData({
      ...storeData,
      skillsOptions: newValue,
    });
  };

  const updateUsersSettings = (newValue = {}) => {
    setStoreData({
      ...storeData,
      usersSettings: { ...newValue },
    });

    // update localStorage for this Changes
    const user = storeData.user;
    user.usersSettings = { ...newValue };
    saveToLocalStorage('user', user);
  };

  const updateAppointmentModal = (newValue = {}) => {
    setStoreData({
      ...storeData,
      appointmentModal: {
        ...storeData.appointmentModal,
        ...newValue,
      },
    });
  };

  const updateNotification = (newValue = {}) => {
    setStoreData({
      ...storeData,
      notification: {
        ...storeData.notification,
        ...newValue,
      },
    });
  };

  const clearAppointmentModal = () => {
    setStoreData({
      ...storeData,
      appointmentModal: defaultValues.appointmentModal,
    });
  };

  return {
    storeData,
    setStoreData,
    updateStoreData,
    userData: storeData.user,
    updateUserData,
    updateSkillsOptions,
    logout,
    usersSettings: storeData.usersSettings,
    skillsOptions: storeData.skillsOptions,
    appointmentModal: storeData.appointmentModal,
    updateAppointmentModal,
    clearAppointmentModal,
    notification: storeData.notification,
    updateNotification,
    updateUsersSettings,
  };
};

export const ProvideGlobalState = ({ children }) => {
  const state = useProvideGlobalState();
  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export const useGlobalState = () => {
  return useContext(GlobalState);
};
