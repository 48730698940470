import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Typography,
} from '@mui/material';

import { getPayments } from '../../API';
import { useGlobalState } from '../../store';

import { API_URL } from '../../utils';
import moment from 'moment';

const defauleParams = {
  page: 0,
  status: null,
  sort: null,
  tradieId: null,
  diyerId: null,
};

const PaymentList = () => {
  const [total, setTotal] = useState(0);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const { updateNotification } = useGlobalState();

  useEffect(() => {
    getRecord();
  }, []);

  const getRecord = async (params = defauleParams) => {
    const { data, message, error } = await getPayments(params);

    if (error) {
      return updateNotification({
        type: 'error',
        title: 'Get Diyer payments.',
        message,
      });
    }

    setTotal(data.total);
    setPayments(payments.concat(data.data));
  };

  const fetchMoreData = async () => {
    const params = {
      page: page + 1,
      status: null,
      sort: null,
      tradieId: null,
      diyerId: null,
    };

    setPage(page + 1);

    getRecord(params);
  };

  return (
    <div
      className="bg-gray-101 h-full gap-2.5 flex flex-col p-4 lg:p-8 gap8 "
      style={{ borderRadius: '15px 15px 0 0' }}>
      <InfiniteScroll
        style={{ overflow: 'hidden ' }}
        dataLength={payments.length}
        next={fetchMoreData}
        hasMore={total > payments.length}
        loader={
          <div className="text-center h-full w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        }>
        {payments.map((payment, index) => {
          return (
            <div
              className="mt-2 mb-0 pb-5 pt-5 lg:rounded-lg hover:cursor-pointer w-full flex justify-between items-center"
              key={payment.id}
              style={{ borderBottom: '1px solid rgb(242, 242, 242)' }}>
              <div className="w-full">
                <div className="diyer flex items-center">
                  <div
                    className="font-bold flex justify-center items-center"
                    style={{ flexDirection: 'column' }}>
                    <Avatar
                      className="mb-2"
                      key={payment.intents?.id}
                      src={`${API_URL}/file/${payment.intents?.usersId}/profile-image/${payment.intents?.intentsUser?.image}`}
                    />
                    <Chip label="Diyer" />
                  </div>

                  <div className="ml-5">
                    <div className="font-bold">
                      {payment.intents?.intentsUser?.firstName}{' '}
                      {payment.intents?.intentsUser?.lastName}
                    </div>
                    <div>
                      Paid:{' '}
                      <span>
                        {payment.paymentDetails?.confirmed?.amount
                          ? `$ ${(
                              payment.paymentDetails?.confirmed?.amount / 100
                            ).toFixed(2)}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="diyer flex items-center">
                  <div
                    className="font-bold flex justify-center items-center"
                    style={{ flexDirection: 'column' }}>
                    <Avatar
                      className="mb-2"
                      key={payment.tradieId}
                      src={`${API_URL}/file/${payment.tradieId}/profile-image/${payment.tradieUser?.usersProfile?.image}`}
                    />
                    <Chip label="Tradie" />
                  </div>

                  <div className="ml-5">
                    <div className="font-bold">
                      {payment.tradieUser?.usersProfile?.firstName}{' '}
                      {payment.tradieUser?.usersProfile?.lastName}
                    </div>
                    <div>
                      Received:{' '}
                      <span>
                        {payment.paramsDetails?.amount
                          ? `$ ${(payment.paramsDetails?.amount / 100).toFixed(
                              2,
                            )}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ml-5">
                {payment.paymentDetails?.confirmed?.status}
              </div>

              <div className="ml-5">
                {moment(payment.createdAt).format('DD/MM/YYYY')}
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default PaymentList;
