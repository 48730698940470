import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import ContactUsComponent from '../components/contactUs';

const Container = styled(Stack)`
  background-color: #e5e5e5;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding: 0px 2rem;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 0px;
  }
`;

const ContactUs = () => {
  return (
    <Container>
      <div className="mt-10 pt-5">
        <Typography
          fontFamily="Roboto"
          sx={{
            gridColumnStart: { xs: 2, lg: 1 },
            fontSize: { xs: 18, lg: 30 },
          }}>
          Contact Us List
        </Typography>
      </div>

      <div className="mt-7 gap-5 flex flex-col xl:flex-row gap-2">
        <div className="bg-white w-full rounded-xl ">
          <ContactUsComponent />
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
