import { Alert, AlertTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../store';

const style = {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: '19999',
  display: 'flex',
  justifyContent: 'center',
};

// we can improve this logic to handle multiple notification at a time
const AlertNotification = () => {
  const { notification, updateNotification } = useGlobalState();

  const [type, setType] = useState('success');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  // reset notification in 3 sec
  useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        updateNotification({
          type: 'info',
          title: '',
          message: '',
        });
      }, 3000);
    }
  }, [message]);

  useEffect(() => {
    setType(notification.type);
    setMessage(notification.message);
    setTitle(notification.title);
  }, [notification]);

  return (
    <div style={{ ...style, display: message !== '' ? 'flex' : 'none' }}>
      <Stack sx={{ width: '40%' }} spacing={2}>
        <Alert severity={type} className="mt-5">
          <AlertTitle>
            {title !== '' ? title : type.toLocaleUpperCase()}
          </AlertTitle>
          {message}
        </Alert>
      </Stack>
    </div>
  );
};

export default AlertNotification;
