import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  Stack,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../store';
import FileUploadArea from '../FileUploadArea';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { createBlog, fetchSkills, patchBlog } from '../../API';
import { omit } from 'lodash';
import Editor from './wysiwyg';
import './styles.css';
import { API_URL } from '../../utils';

// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

// validation
const closeIcon = {
  position: 'absolute',
  right: '5px',
  top: '5px',
  cursor: 'pointer',
  fill: '#fff',
  opacity: '90%',
  backgroundColor: '#000',
  borderRadius: '50%',
};
const schema = yup.object().shape({
  title: yup.string().min(4).required(),
  slug: yup.string().min(4).required(),
  description: yup.string().min(4).required(),
  user_skills: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
      }),
    )
    .min(1, 'please add skills'),
});

function BlogForm({ open, closeModal, handleRefresh, blog }) {
  const { skillsOptions, updateSkillsOptions, updateNotification } =
    useGlobalState();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState('add');
  const [existingFiles, setExistingFiles] = useState([]);
  const [alReadySelectedSkills, setAlReadySelectedSkills] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [status, setStatus] = useState('active');

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      user_skills: [],
      slug: '',
      status: 'active',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (blog) {
      setValue('title', blog.title);
      setValue('description', blog.description);
      setValue('user_skills', blog.skillsId);
      setValue('slug', blog.slug);
      setValue('images', blog.images);
      setValue('status', blog.status);
      setValue('wysiwigDescription', blog.wysiwigDescription);
      setExistingFiles(blog.images);
      setAlReadySelectedSkills([...blog.skillsId]);
    }
    setMethod(blog ? 'update' : 'add');
  }, [blog]);

  const onSubmit = async (payload) => {
    if (loading) return null;
    setLoading(true);
    const skillsId = payload.user_skills;

    payload.slug = payload.slug.replaceAll(' ', '-');
    const submitPayload = {
      ...omit(payload, 'user_skills'),
      skillsId,
      images: files,
      deletedImages,
    };
    if (method === 'add') {
      const { message, error } = await createBlog(submitPayload);
      setLoading(false);
      if (error) {
        return updateNotification({
          type: 'error',
          title: 'Add Blog failed!',
          message,
        });
      }

      updateNotification({
        type: 'success',
        title: 'Blog',
        message: 'Successfully created Blog.',
      });
    } else {
      submitPayload.images = [...submitPayload.images.concat(existingFiles)];
      const { message, error } = await patchBlog(blog.id, submitPayload);
      setLoading(false);
      if (error) {
        return updateNotification({
          type: 'error',
          title: 'Update Blog failed!',
          message,
        });
      }

      updateNotification({
        type: 'success',
        title: 'Blog',
        message: 'Successfully updated Blog.',
      });
    }

    reset();

    handleRefresh();
    closeModal();
  };

  useEffect(() => {
    const getSkills = async () => {
      const { data } = await fetchSkills();

      if (data) {
        updateSkillsOptions(data);
      }
    };
    if (!skillsOptions.length) getSkills();
  }, [skillsOptions, updateSkillsOptions]);

  const handleCloseModal = () => {
    reset();
    setAlReadySelectedSkills([]);
    closeModal();
  };
  const onError = (errors, e) => console.log(errors, e);

  const handleRemoveImage = (image) => {
    const index = existingFiles.findIndex(({ id }) => id === image.id);
    if (index > -1) {
      deletedImages.push(existingFiles[index]);
      setDeletedImages([...deletedImages]);

      existingFiles.splice(index, 1);
      setExistingFiles([...existingFiles]);
    }
  };
  return (
    <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle style={{ borderBottom: '1px solid #f2f2f2' }}>
        <Typography variant="h5" className="text-base font-medium" py={1.5}>
          {method === 'update' ? 'Update' : 'Add New'} Blog
          <IconButton onClick={handleCloseModal} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Stack direction="column" spacing="10px" px={3} py={4.5}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  type="text"
                  label="Title"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    className: 'font-inherit rounded-md w-full',
                  }}
                  InputLabelProps={{
                    className: 'font-inherit ',
                  }}
                />
              )}
            />
            <Controller
              name="details"
              control={control}
              render={({ field }) => (
                <div className="pt-10 pb-10">
                  <Editor
                    defaultValue={blog?.wysiwigDescription}
                    handleChange={(value, jsonData) => {
                      setValue('description', value);
                      setValue('wysiwigDescription', jsonData);
                    }}
                  />
                </div>
              )}
            />

            <Controller
              name="user_skills"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Autocomplete
                  {...field}
                  multiple={true}
                  disableClearable
                  options={skillsOptions}
                  onChange={(_, item) => {
                    onChange(item);
                  }}
                  defaultValue={alReadySelectedSkills}
                  getOptionLabel={(item) => (item.name ? item.name : '')}
                  popupIcon={null}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Typography
                        key={index}
                        variant="caption"
                        sx={{
                          py: 0.5,
                          px: 1.25,
                          border: '1px solid #E7E7E7',
                          backgroundColor: 'white',
                          mr: 1.25,
                        }}>
                        {option.name}
                      </Typography>
                    ))
                  }
                  className="w-full"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Add skills / tags for this job post'}
                      variant="filled"
                      className="font-roboto"
                      error={!!errors.user_skills}
                      helperText={errors.user_skills?.message}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="slug"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  error={!!errors.slug}
                  helperText={errors.slug?.message}
                  type="text"
                  label="Slug"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    className: 'font-inherit rounded-md w-full',
                  }}
                  InputLabelProps={{
                    className: 'font-inherit ',
                  }}
                />
              )}
            />

            <Select
              id="status"
              name="status"
              value={status}
              label="Status"
              onChange={(e) => {
                setValue('status', e.target.value);
                setStatus(e.target.value);
              }}>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>

            <Typography variant="subtitle1">
              Add images / documents for this post
            </Typography>

            <ImageList variant="quilted" cols={3} rowHeight={150}>
              {existingFiles.map((img, index) => {
                // if (img.status === 'deleted') return;
                return (
                  <ImageListItem key={img.id} cols={1} rows={1}>
                    <CloseIcon
                      style={closeIcon}
                      onClick={() => handleRemoveImage(img)}
                    />
                    <img
                      alt={`pic${index}`}
                      crossOrigin="anonymous"
                      src={`${API_URL}/file/admin/blogs-images/${img}`}
                      style={{ width: '100%', height: '100%' }}
                      loading="lazy"
                    />
                  </ImageListItem>
                );
              })}
              {files.map((img, index) => (
                <ImageListItem key={img.image} cols={1} rows={1}>
                  <img
                    alt={`pic${index}`}
                    src={img}
                    style={{ width: '100%', height: '100%' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <FileUploadArea onUpdateFiles={setFiles} />

            <Button
              type="submit"
              style={{
                backgroundColor: '#048DDB',
                color: 'white',
                width: 105,
                height: 48,
                alignSelf: 'flex-end',
                marginTop: 22,
              }}
              startIcon={<SaveIcon />}>
              Save
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default BlogForm;
