import { ReactComponent as Filter } from '../assets/images/icons/filter.svg';
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
export function SortButton({ handleSort }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="px-4">
      <Filter onClick={handleClick} className="cursor-pointer" />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSort('-1');
          }}
          className="w-full">
          Sort - Ascending
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSort('1');
            handleClose();
          }}
          className="w-full">
          Sort - Descending
        </MenuItem>
      </Menu>
    </div>
  );
}
