import {
  Avatar,
  IconButton,
  InputBase,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { ReactComponent as LayoutGridIcon } from '../assets/images/icons/layout-grid.svg';
import { Search, VerifiedUser } from '@mui/icons-material';

const Container = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
`;

const RightSection = styled('div')``;

export default function PageHeader({ title, onClickCTA }) {
  return (
    <Container
      sx={{
        background: 'white',
        position: 'sticky',
        padding: '2.5rem 2rem',
      }}
      className="items-center">
      <Typography
        fontFamily="Roboto"
        sx={{
          gridColumnStart: { xs: 2, lg: 1 },
          justifySelf: { xs: 'center', lg: 'flex-start' },
          fontSize: { xs: 18, lg: 30 },
        }}>
        {title}
      </Typography>
      <RightSection
        sx={{
          width: 'fit-content',
          justifySelf: 'flex-end',
          alignItems: 'center',
          justifyContent: 'center',
          gridColumnStart: { lg: 3 },
          flexDirection: 'row',
          display: 'flex',
          gap: '21px',
        }}>
        <InputBase
          startAdornment={<Search />}
          placeholder="Search jobs..."
          sx={{
            background: '#f5f5f5',
            borderRadius: '10px',
            height: '48px',
            paddingX: '24px',
          }}
        />
        <div className="flex flex-row gap-2 items-center font-bold border-l-2 border-gray pl-10">
          <Avatar src="" />
          Teletradie Admin
        </div>
      </RightSection>
    </Container>
  );
}
