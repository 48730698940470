import styled from '@emotion/styled';
import { IconButton, Stack, Typography } from '@mui/material';
import BlogLists from '../components/blogs';
import { ReactComponent as LayoutGridIcon } from '../assets/images/icons/layout-grid.svg';
import { useState } from 'react';

import useFetchBlogs from '../hooks/useFetchBlogs';
import BlogForm from '../components/blogs/BlogForm';

const Container = styled(Stack)`
  background-color: #e5e5e5;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding: 0px 2rem;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 0px;
  }
`;

const HeaderContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
`;

const tabItems = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

const Blogs = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabItems[0].value);

  const [updateBlog, setUpdateBlog] = useState();
  const { blogs, refreshData, total, fetchMoreData } = useFetchBlogs({
    status: selectedTab,
    sort: '-1',
    id: null,
  });

  return (
    <Container>
      <HeaderContainer
        className="mt-10 pt-5"
        sx={{ mt: { xs: 4, lg: 6.5 }, px: { xs: 2, lg: 0 } }}>
        <Typography
          fontFamily="Roboto"
          sx={{
            gridColumnStart: { xs: 2, lg: 1 },
            justifySelf: { xs: 'center', lg: 'flex-start' },
            fontSize: { xs: 18, lg: 30 },
          }}>
          Blogs
        </Typography>
        <IconButton
          onClick={() => setIsAdding(!isAdding)}
          sx={{
            width: 'fit-content',
            justifySelf: 'flex-end',
            gridColumnStart: { lg: 3 },
          }}>
          <LayoutGridIcon />
        </IconButton>
      </HeaderContainer>

      <div className="mt-7 gap-5 flex flex-col xl:flex-row gap-2">
        <div className="bg-white w-full rounded-xl ">
          <BlogForm
            open={isAdding || updateBlog}
            closeModal={() => {
              setIsAdding(false);
              setUpdateBlog(null);
            }}
            handleRefresh={refreshData}
            blog={updateBlog}
          />

          <BlogLists
            blogs={blogs}
            fetchMoreData={fetchMoreData}
            tabOptions={tabItems}
            blogsTotal={total}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            updateThisBlog={setUpdateBlog}
          />
        </div>
      </div>
    </Container>
  );
};

export default Blogs;
