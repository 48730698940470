import { createContext, useState, useContext, useEffect } from 'react';
import io from 'socket.io-client';

const GlobalSocket = createContext();

export const useProvideGlobalSocket = () => {
  // const [socketio, setSocketIO] = useState(
  //   io(process.env.REACT_APP_API_URL || 'http://localhost:3030'),
  // );
  // const [newMessage, setNewMessage] = useState('');
  // const [incomingCall, setIncomingCall] = useState('');
  // const [newAppointment, setNewAppointment] = useState('');
  // const [newJobResponse, setNewJobResponse] = useState();
  // const [hasAcceptedCall, setHasAcceptedCall] = useState(false);

  useEffect(() => {
    // socketio.on('connect', () => {
    //   socketio.emit(
    //     'create',
    //     'authentication',
    //     {
    //       strategy: 'jwt',
    //       accessToken: localStorage.getItem('accessToken'),
    //     },
    //     (error) => {
    //       if (error) {
    //         return console.error(error);
    //       }
    //     },
    //   );
    // });
    // setSocketIO(socketio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // subscribe to my incoming messages
    // socketio.on('messages created', function (message) {
    //   setNewMessage(message);
    // });

    // // subscribe to my incoming job response
    // socketio.on('jobs-response created', function (response) {
    //   setNewJobResponse(response);
    // });

    // // subscribe to my incoming appointments
    // socketio.on('appointments created', function (appointment) {
    //   setNewAppointment(appointment);
    // });

    // //subscribe to incoming calls
    // socketio.on('video-call created', function (conversationDetails) {
    //   setIncomingCall(conversationDetails);
    // });

    // // unsubscribe when reloading to avoid duplicate triggers
    // return () => {
    //   socketio.off('messages created');
    //   socketio.off('jobs-response created');
    //   socketio.off('appointments created');
    //   socketio.off('video-call created');
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authenticateSocket = (data) => {
    // socketio.emit(
    //   'create',
    //   'authentication',
    //   {
    //     strategy: 'local',
    //     email: data.email,
    //     password: data.password,
    //   },
    //   (error) => {
    //     if (error) {
    //       return console.error(error);
    //     }
    //     setSocketIO(socketio);
    //   },
    // );
    //
  };

  // const handleRemoveNewMessage = () => setNewMessage('');
  const handleAcceptIncomingCall = () => {
    // setIncomingCall('');
    // setHasAcceptedCall(true);
  };
  const handleDeclineIncomingCall = () => {
    // setIncomingCall('');
    // setHasAcceptedCall(false);
  };

  return {
    // socketio,
    // authenticateSocket,
    // newMessage,
    // handleRemoveNewMessage,
    // incomingCall,
    // newJobResponse,
    // setNewJobResponse,
    // handleAcceptIncomingCall,
    // handleDeclineIncomingCall,
    // handleClearIncomingCall: handleDeclineIncomingCall,
    // hasAcceptedCall,
    // newAppointment,
  };
};

export const ProvideGlobalSocket = ({ children }) => {
  const state = useProvideGlobalSocket();
  return (
    <GlobalSocket.Provider value={state}>{children}</GlobalSocket.Provider>
  );
};

export const useGlobalSocket = () => {
  return useContext(GlobalSocket);
};
