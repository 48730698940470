import axios from 'axios';
import { API_URL } from '../utils';

export const handleRefreshError = async () => {
  localStorage.clear();
  window.location.reload();
};

const api = axios.create();
// createAuthRefreshInterceptor(api, handleRefreshError);

// const API_URL =
//   process.env.REACT_APP_API_URL || `${window?.location.origin}:8080`;

// use this config to include
const config = {
  headers: {
    Authorization: localStorage.getItem('accessToken') || null,
    'content-type': 'application/json',
  },
};

// default response
const DEFAULT_RESULT = {
  error: false,
  message: '',
  data: null,
};
let result = {
  ...DEFAULT_RESULT,
};
// set error data
const setErrorData = (error) => {
  if (error) {
    result.error = true;
    const data = error?.response?.data;

    if (data.errors && data.errors.length) {
      result.message = data.errors[0]?.message;
      return result;
    }

    result.message = data.message;
    return result;
  }
};

export const getTradiePayments = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/admin/payments`, config);
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getBlogs = async ({ sort = '-1', status, page }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const params = {
      limit: 10,
      skip: page * 10,
    };
    // construct params
    const paramsQuery = `?$sort[createdAt]=${sort}&$limit=${params.limit}&$skip=${params.skip}&status=${status}`;

    const { data } = await api.get(`${API_URL}/blogs/${paramsQuery}`, config);
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getTotalFeedBack = async ({ id }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    // construct params

    const { data } = await api.get(
      `${API_URL}/users/${id}/total/ratings`,
      config,
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getFeedBack = async ({ sort = '-1', id, page }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const params = {
      limit: 10,
      skip: page * 10,
    };
    // construct params
    const paramsQuery = `?$sort[createdAt]=${sort}&$limit=${params.limit}&$skip=${params.skip}&receiverId=${id}`;

    const { data } = await api.get(
      `${API_URL}/feedbacks/${paramsQuery}`,
      config,
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getContactUsList = async ({ sort = '-1', page }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const params = {
      limit: 10,
      skip: page * 10,
    };
    // construct params
    const paramsQuery = `?$sort[createdAt]=${sort}&$limit=${params.limit}&$skip=${params.skip}`;

    const { data } = await api.get(
      `${API_URL}/contact-us/${paramsQuery}`,
      config,
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const createBlog = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/blogs`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const patchBlog = async (id, payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.patch(`${API_URL}/blogs/${id}`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getCallLogs = async ({ sort = '-1', status, userId, page }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const params = {
      limit: 10,
      skip: page * 10,
    };
    // construct params
    let paramsQuery = `?$sort[createdAt]=${sort}&$limit=${params.limit}&$skip=${params.skip}`;

    if (userId) {
      paramsQuery = `${paramsQuery}&usersId=${userId}`;
    }

    if (status) {
      paramsQuery = `${paramsQuery}&status=${status}`;
    }

    const { data } = await api.get(
      `${API_URL}/admin/calls/${paramsQuery}`,
      config,
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getPayments = async ({ sort = '-1', status, userId, page }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const params = {
      limit: 10,
      skip: page * 10,
    };
    // construct params
    let paramsQuery = `?$sort[createdAt]=${sort}&$limit=${params.limit}&$skip=${params.skip}`;

    if (userId) {
      paramsQuery = `${paramsQuery}&usersId=${userId}`;
    }

    if (userId) {
      paramsQuery = `${paramsQuery}&status=${status}`;
    }

    const { data } = await api.get(
      `${API_URL}/admin/payments/${paramsQuery}`,
      config,
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const signIn = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(
      `${API_URL}/authentication/admin`,
      payload,
      config,
    );
    if (data.user) {
      result.data = data.user;
      result.message = 'Login Successful';
    }
    // update accesstoken
    if (data.accessToken) {
      config.headers['Authorization'] = `Bearer ${data.accessToken}`;

      //save token to localStorage
      localStorage.setItem('accessToken', data.accessToken);
    }
  } catch (error) {
    setErrorData(error);
  }

  return result;
};
export const getDashboardInfo = async () => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/admin/dashboard`, config);
    result.data = data[0];
  } catch (error) {
    setErrorData(error);
  }
  return result;
};
export const getTradieList = async ({
  page,
  id,
  status = 'pending',
  sort = '-1',
}) => {
  const params = {
    limit: 10,
    skip: page * 10,
  };
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/admin/users/?usersTypeId=2&status=${status}&$limit=${params.limit}&$skip=${params.skip}`,
      {
        ...config,
      },
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};
export const getDiyerList = async ({
  page,
  id,
  status = 'pending',
  sort = '-1',
}) => {
  const params = {
    limit: 10,
    skip: page * 10,
  };
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/admin/users/?usersTypeId=1&$limit=${params.limit}&$skip=${params.skip}&status=${status}`,
      {
        ...config,
      },
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getWaitingJobList = async ({ page, sort = '-1' }) => {
  const params = {
    limit: 10,
    skip: page * 10,
  };
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/admin/jobs/?$limit=${params.limit}&$skip=${params.skip}&$or[0][status]=waiting_for_tradie&$or[1][status]=waiting_for_diyer&$or[2][status]=waiting_for_callback`,
      {
        ...config,
      },
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getJobList = async ({
  page,
  id,
  status = 'active',
  sort = '-1',
}) => {
  const params = {
    limit: 10,
    skip: page * 10,
  };
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/admin/jobs/?$limit=${params.limit}&$skip=${params.skip}&status=${status}`,
      {
        ...config,
      },
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};
export const updateUserState = async (payload, id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.patch(
      `${API_URL}/admin/users/${id}`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getProfileByUserId = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/admin/users/${id}`, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchSkills = async () => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const {
      data: { data },
    } = await api.get(`${API_URL}/skills`, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const registerUser = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/users`, payload);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getProfile = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/users-profile/${id}`, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const updateProfile = async ({ usersProfileId, ...payload }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.patch(
      `${API_URL}/users-profile/${usersProfileId}`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const verifyEmail = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(
      `${API_URL}/authManagement/`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

// export const createProfile = async (payload) => {
//   result = {
//     ...DEFAULT_RESULT,
//   };
//   try {
//     const { data } = await api.post(
//       `${API_URL}/users-profile`,
//       payload,
//       config,
//     );

//     result.data = data;
//   } catch (error) {
//     setErrorData(error);
//   }
//   return result;
// };

export const fetchUsersSettings = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const {
      data: { data },
    } = await api.get(`${API_URL}/users-settings/${id}`, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const patchUsersSettings = async (id, payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const {
      data: { data },
    } = await api.patch(`${API_URL}/users-settings/${id}`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const requestResetPassword = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/authManagement`, payload);

    if (data) {
      const { email, id, usersTypeId } = data;
      result.data = { email, id, usersTypeId };
      result.message = 'Request Sent';
    }
  } catch (error) {
    setErrorData(error);
  }

  return result;
};

export const fetchJobs = async ({ page, id, status, sort = '-1' }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  const params = {
    limit: 10,
    skip: page * 10,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/jobs/?$limit=${params.limit}&$skip=${
        params.skip
      }&$sort[createdAt]=${sort}${id ? `&usersId=${id}` : ''}${
        status
          ? `&status=${status}`
          : '&status[$in][]=active&status[$in][]=draft&status[$in][]=past'
      }`,
      {
        ...config,
      },
    );
    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchJobsByUserId = async ({ page, id }) => {
  result = {
    ...DEFAULT_RESULT,
  };
  const params = {
    limit: 10,
    skip: page * 10,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/jobs/?$limit=${params.limit}&$skip=${params.skip}&$sort[createdAt]=-1&userId=${id}&status[$in][]=active&status[$in][]=draft&status[$in][]=past`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchJobInfo = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/jobs/${id}`, {
      ...config,
    });

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const createJob = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/jobs`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const patchJob = async (id, payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.patch(`${API_URL}/jobs/${id}`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const createAppointment = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await axios.post(
      `${API_URL}/appointments`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getJobResponse = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };

  try {
    const { data } = await api.get(`${API_URL}/jobs-response/${id}`, {
      ...config,
    });

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchJobResponses = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };

  try {
    const { data } = await api.get(
      `${API_URL}/jobs-response/?jobsId=${id}&$sort[createdAt]=-1&$limit=1000`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const createJobResponses = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(
      `${API_URL}/jobs-response`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchMessages = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };

  try {
    const { data } = await api.get(
      `${API_URL}/messages/?conversationsId=${id}&$limit=100`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const createMessage = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/messages`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchConversations = async () => {
  result = {
    ...DEFAULT_RESULT,
  };

  try {
    const { data } = await api.get(
      `${API_URL}/conversations/?$sort[updatedAt]=-1`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchVideoCall = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };

  try {
    const { data } = await api.get(`${API_URL}/video-call/${id}`, {
      ...config,
    });

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const postVideoCall = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/video-call`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getConversationId = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(
      `${API_URL}/conversations`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchAppointments = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await axios.get(
      // `${API_URL}/appointments?$limit=-1&$skip=0&$sort[createdAt]=-1&status=active`,
      `${API_URL}/appointments?$limit=1000&$skip=0&$sort[createdAt]=-1`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const uploadDocument = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(
      `${API_URL}/users-documents`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const fetchUserDocuments = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/users-documents?$sort[createdAt]=-1&usersId=${id}`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const createWorkedOnJobs = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(
      `${API_URL}/worked-on-jobs`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const updateWorkedOnJobs = async (payload, id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.patch(
      `${API_URL}/worked-on-jobs/${id}`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getWorkedOnJob = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/worked-on-jobs?jobsId=${id}`, {
      ...config,
    });

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getAppointmentById = async (jobsId, appointeeId, appointerId) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/appointments?jobsId=${jobsId}&appointerId=${appointerId}&appointeeId=${appointeeId}`,
      {
        ...config,
      },
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const updateAppointment = async (payload, id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.patch(
      `${API_URL}/appointments/${id}`,
      payload,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const likeAJob = async (payload) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.post(`${API_URL}/jobs-liked/`, payload, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getJob = async (jobsId) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(`${API_URL}/jobs/${jobsId}`, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const getLikeJob = async (jobsId, usersId) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.get(
      `${API_URL}/jobs-liked/?jobsId=${jobsId}&usersId=${usersId}`,
      config,
    );

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};

export const unLikeAJob = async (id) => {
  result = {
    ...DEFAULT_RESULT,
  };
  try {
    const { data } = await api.delete(`${API_URL}/jobs-liked/${id}`, config);

    result.data = data;
  } catch (error) {
    setErrorData(error);
  }
  return result;
};
