import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import LoginSection from '../layout/LoginSection';
import Logo from '../assets/images/logo-vertical.svg';
import { ReactComponent as BackIcon } from '../assets/images/icons/arrow-left-dark.svg';

const PageNotFound = () => {
  return (
    <LoginSection disableSlide>
      <div className="flex h-full w-full justify-center items-center relative pt-56 lg:pt-36 pb-36 px-10 text-[#000]">
        <Link to="/">
          <img
            src={Logo}
            alt="teletradie"
            className="absolute left-1/2 -translate-x-1/2 top-12"
          />
        </Link>
        <div className="text-center max-w-[350px] m-auto">
          <h1 className="text-[180px] leading-none font-medium">404</h1>
          <h2 className="text-3xl md:text-[32px] -mt-[20px] opacity-60 font-medium mb-16">
            Oops! Page Not Found
          </h2>

          <p className="leading-normal opacity-60 max-w-[340px] mb-[50px]">
            This page doesn’t exist or was removed! We suggest you go back to
            home or if you already have an account go to login page.
          </p>

          <Link to="/sign-in">
            <Button
              type="button"
              className="bg-gradient-to-b from-bgGradientStart to-bgGradientEnd text-white normal-case font-bold p-2.5 text-base rounded-lg mb-6"
              fullWidth={true}>
              Login
            </Button>
          </Link>

          <Link to="/">
            <Button
              className="normal-case underline font-medium font-inherit text-sm text-inherit"
              startIcon={<BackIcon />}>
              Go back to homepage
            </Button>
          </Link>
        </div>
      </div>
    </LoginSection>
  );
};

export default PageNotFound;
