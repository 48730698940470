import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ButtonBase,
  Card,
  CircularProgress,
  Typography,
} from '@mui/material';

import { getCallLogs } from '../../API';
import { useGlobalState } from '../../store';

import { API_URL } from '../../utils';
import moment from 'moment';

const defauleParams = {
  page: 0,
  status: null,
  sort: null,
  tradieId: null,
  diyerId: null,
};

const CallList = () => {
  const [total, setTotal] = useState(0);
  const [calls, setCalls] = useState([]);
  const [page, setPage] = useState(0);
  const { updateNotification } = useGlobalState();

  useEffect(() => {
    getRecord();
  }, []);

  const getRecord = async (params = defauleParams) => {
    const { data, message, error } = await getCallLogs(params);

    if (error) {
      return updateNotification({
        type: 'error',
        title: 'Get Calls.',
        message,
      });
    }

    setTotal(data.total);
    setCalls(calls.concat(data.data));
  };

  const fetchMoreData = async () => {
    const params = {
      page: page + 1,
      status: null,
      sort: null,
      tradieId: null,
      diyerId: null,
    };

    setPage(page + 1);

    getRecord(params);
  };
  console.log({ calls });
  return (
    <div
      className="bg-gray-101 h-full gap-2.5 flex flex-col p-4 lg:p-8 gap8 "
      style={{ borderRadius: '15px 15px 0 0' }}>
      <InfiniteScroll
        style={{ overflow: 'hidden ' }}
        dataLength={calls.length}
        next={fetchMoreData}
        hasMore={total > calls.length}
        loader={
          <div className="text-center h-full w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        }>
        {calls.map((call, index) => {
          return (
            <Card
              className="mt-2 mb-0 px-8 py-5 lg:rounded-lg hover:cursor-pointer w-full flex justify-between items-center"
              key={call.id}
              style={{ borderBottom: '1px solid rgb(242, 242, 242)' }}>
              <div className="flex flex-col gap-1 w-[300px]">
                <Typography sx={{ color: '#0587D8', fontSize: '16px' }}>
                  {call.callJobs.title}
                </Typography>
                <Typography sx={{ color: '#7c7c7c', fontSize: '12px' }}>
                  {call.callJobs.details}
                </Typography>
              </div>
              <div>
                <Typography sx={{ color: '#7c7c7c', fontSize: '12px' }}>
                  Posted by:
                </Typography>
                <div className="flex flex-row gap-2 items-center w-[170px]">
                  <Avatar src="" sx={{ width: 24, height: 24 }} />
                  <Typography>
                    {call.callUsersSender.usersProfile.firstName}
                    {` `}
                    {call.callUsersSender.usersProfile.lastName}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography sx={{ color: '#7c7c7c', fontSize: '12px' }}>
                  Assisted by:
                </Typography>
                <div className="flex flex-row gap-2 items-center w-[170px]">
                  <Avatar src="" sx={{ width: 24, height: 24 }} />
                  <Typography>
                    {call.callUsersReceiver.usersProfile.firstName}
                    {` `}
                    {call.callUsersReceiver.usersProfile.lastName}
                  </Typography>
                </div>
              </div>

              <div>
                <Typography sx={{ color: '#7c7c7c', fontSize: '12px' }}>
                  Call duration:
                </Typography>
                <div className="flex flex-row gap-2 items-center">
                  <Typography
                    sx={{
                      color: '#2c2c2c',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}>
                    {call.callJobs.duration || '00:00:00'}
                  </Typography>
                </div>
              </div>

              <div>
                <Typography sx={{ color: '#7c7c7c', fontSize: '12px' }}>
                  Total Cost:
                </Typography>
                <div className="flex flex-row gap-2 items-center">
                  <Typography
                    sx={{
                      color: '#2c2c2c',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}>
                    $51.24
                  </Typography>
                </div>
              </div>
              <ButtonBase
                sx={{
                  border: 'solid 1px black',
                  borderRadius: '50px',
                  background: 'white',
                  padding: '8px',
                  width: '150px',
                  fontWeight: '700',
                }}>
                View Details
              </ButtonBase>
            </Card>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default CallList;
