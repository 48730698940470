import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Icon } from '../assets/images/icons/file_upload.svg';

function FileUploadArea({ onUpdateFiles }) {
  const [files, setFiles] = useState([]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFiles([...files, reader.result]);
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => getBase64(file));
    },
  });

  useEffect(() => {
    onUpdateFiles(files);
  }, [files]);

  return (
    <>
      <Stack
        {...getRootProps({ className: 'dropzone' })}
        height={184}
        alignItems="center"
        justifyContent="center"
        gap="1rem"
        sx={{
          border: '1px dashed rgba(19, 19, 19, 0.5)',
          borderRadius: 2,
        }}>
        <Icon />

        <Typography variant="body1" color="#a1a1a1">
          {isDragActive ? 'Drop files here' : 'Choose a file or drag it here.'}
        </Typography>
        <input {...getInputProps()} />
      </Stack>
    </>
  );
}

export default FileUploadArea;
