import moment from 'moment';

export const getNameDateDiff = (prevDate, newDate, isFull = true) => {
  const diff = moment.duration(newDate.diff(prevDate));
  const dateDiff = Math.floor(diff.asDays());
  const hoursDiff = Math.floor(diff.asHours());
  const minutesDiff = Math.floor(diff.asMinutes());

  if (!isFull) {
    if (dateDiff > 0) return `${dateDiff}d`;
    if (hoursDiff > 0) return `${hoursDiff}h`;
    if (minutesDiff > 0) return `${minutesDiff}m`;
  }

  if (dateDiff > 0) return `${dateDiff} day${dateDiff !== 1 ? 's' : ''} ago`;
  if (hoursDiff > 0)
    return `${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''} ago`;
  if (minutesDiff > 0)
    return `${minutesDiff} minute${minutesDiff !== 1 ? 's' : ''} ago`;
};
