import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Chip from '@mui/material/Chip';
import { CircularProgress } from '@mui/material';

import { getContactUsList } from '../../API';
import { useGlobalState } from '../../store';

import { API_URL } from '../../utils';
import moment from 'moment';

const defauleParams = {
  page: 0,
  status: null,
  sort: null,
  tradieId: null,
  diyerId: null,
};

const ContactUsComponent = () => {
  const [total, setTotal] = useState(0);
  const [contactUs, setContactUs] = useState([]);
  const [page, setPage] = useState(0);
  const { updateNotification } = useGlobalState();

  useEffect(() => {
    getRecord();
  }, []);

  const getRecord = async (params = defauleParams) => {
    const { data, message, error } = await getContactUsList(params);

    if (error) {
      return updateNotification({
        type: 'error',
        title: 'Get ContactUs.',
        message,
      });
    }

    setTotal(data.total);
    setContactUs(contactUs.concat(data.data));
  };

  const fetchMoreData = async () => {
    const params = {
      page: page + 1,
      status: null,
      sort: null,
      tradieId: null,
      diyerId: null,
    };

    setPage(page + 1);

    getRecord(params);
  };

  return (
    <div
      className="bg-gray-101 h-full gap-2.5 flex flex-col p-4 lg:p-8 gap8 "
      style={{ borderRadius: '15px 15px 0 0' }}>
      <InfiniteScroll
        style={{ overflow: 'hidden ' }}
        dataLength={contactUs.length}
        next={fetchMoreData}
        hasMore={total > contactUs.length}
        loader={
          <div className="text-center h-full w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        }>
        {contactUs.map((list) => {
          return (
            <div
              className="mt-2 mb-0 pb-5 pt-5 lg:rounded-lg hover:cursor-pointer w-full flex justify-evenly items-center"
              key={list.id}
              style={{ borderBottom: '1px solid rgb(242, 242, 242)' }}>
              <div className="w-full">
                <div className="diyer flex items-center">
                  <div
                    className="font-bold flex justify-center items-center"
                    style={{ flexDirection: 'column' }}>
                    {list.name}
                    <Chip label={`${list.email}`} />
                  </div>

                  <div className="ml-5">
                    <div className="font-bold">{list.phoneNumber}</div>
                  </div>
                </div>
              </div>
              <div className="w-full">{list.subject}</div>

              <div className="w-full">{list.message}</div>

              <div className="w-full">
                {moment(list.createdAt).format('DD/MM/YYYY')}
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ContactUsComponent;
