import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Typography } from '@mui/material';
import CustomTabs from '../common/custom-tabs';
import { BlogItem } from './BlogItem';
import { SortButton } from '../SortButton';

const BlogLists = ({
  blogs,
  fetchMoreData,
  blogsTotal,
  tabOptions,
  selectedTab,
  setSelectedTab,
  updateThisBlog,
}) => {
  const [blogState, setBlogState] = useState([]);

  useEffect(() => {
    setBlogState([...blogs]);
  }, [blogs]);
  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-grayLightV2 ">
        <CustomTabs
          options={tabOptions}
          value={selectedTab}
          handleChange={setSelectedTab}
        />
        {/* <SortButton handleSort={() => {}} /> */}
      </div>
      <div
        key={selectedTab}
        className="bg-gray-101 h-full gap-2.5 flex flex-col p-4 lg:p-8 gap8">
        {blogState.length > 0 && (
          <InfiniteScroll
            dataLength={blogState.length}
            next={fetchMoreData}
            hasMore={blogsTotal > blogState.length}
            loader={
              <div className="text-center h-full w-full flex justify-center items-center">
                <CircularProgress />
              </div>
            }>
            {blogState.map((blog) => {
              return (
                <BlogItem
                  key={blog.id}
                  blog={blog}
                  updateThisBlog={updateThisBlog}
                />
              );
            })}
          </InfiniteScroll>
        )}

        {blogState.length === 0 && (
          <div className="text-center h-full w-full">
            <Typography>No record found.</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogLists;
