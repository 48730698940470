import { useCallback, useState } from 'react';

export const encodeImageFileAsURL = (element, callback) => {
  const file = element[0];
  const reader = new FileReader();
  reader.onloadend = function () {
    if (callback) callback(reader.result);
  };
  reader.readAsDataURL(file);
};

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  return [state, toggle];
};

export const API_URL =
  'https://api.teletradie.com' || `${window?.location.origin}:8080`;

export const FE_URL = 'https://www.teletradie.com';
