import { useState, useEffect } from 'react';
import { getBlogs } from '../API';

const useFetchBlogs = ({ id = undefined, status = undefined, sort = '-1' }) => {
  const [blogs, setblogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllblogs = async () => {
    if (loading) return null;
    setLoading(true);
    const res = await getBlogs({ page, id, status, sort });

    setblogs(blogs.concat(res.data.data));
    setTotal(res.data.total);
    setLoading(false);
  };

  const fetchMoreData = () => {
    getAllblogs();
  };

  const refreshData = async () => {
    if (loading) return null;
    setPage(0);
    setLoading(true);

    const res = await getBlogs({ page: 0, id, status, sort });

    setblogs(res.data.data);
    setTotal(res.data.total);
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
  }, [status, sort]);

  useEffect(() => {
    setPage(Math.ceil(blogs.length / 10));
  }, [blogs]);

  return {
    blogs,
    loading,
    setPage,
    refreshData,
    fetchMoreData,
    total,
  };
};

export default useFetchBlogs;
