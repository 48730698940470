/* eslint-disable react-hooks/exhaustive-deps */
import { ImageList, ImageListItem, Typography } from '@mui/material';
import React from 'react';

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PublicIcon from '@mui/icons-material/Public';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';

import { useState } from 'react';

import { useEffect } from 'react';
import { getNameDateDiff } from '../../utils/date';
import moment from 'moment';
import { get, map, find } from 'lodash';
import { useGlobalState } from '../../store';
import { fetchSkills } from '../../API';

import { API_URL, FE_URL } from '../../utils';

// const statusColor = {
//   waiting_for_tradie: {
//     background: '#1bb55c',
//   },
//   waiting_for_callback: {
//     background: '#e9be15',
//   },
//   waiting_for_diyer: {
//     background: '#1bb55c',
//   },
// };

export function BlogItem({ blog, updateThisBlog }) {
  const [expanded, setExpanded] = useState(false);
  const [mainImage, setMainImage] = useState('');

  const { skillsOptions, updateSkillsOptions } = useGlobalState();

  const getSkills = async () => {
    const { data } = await fetchSkills();

    if (data) {
      updateSkillsOptions(data);
    }
  };

  useEffect(() => {
    if (!skillsOptions.length) getSkills();
  }, []);

  useEffect(() => {
    setExpanded(false);
  }, [blog.id]);

  useEffect(() => {
    setMainImage(
      blog.images.length > 0
        ? `${API_URL}/file/admin/blogs-images/${blog.images[0]}`
        : `/no-image-icon.png`, // temp stock image
    );
  }, [blog]);

  return (
    <div
      onClick={() => updateThisBlog(blog)}
      className="border border-grayLightV2 bg-gray-101 mb-2.5 lg:mb-8 lg:rounded-lg hover:cursor-pointer">
      <div className="w-full block lg:hidden">
        <img
          className="w-full aspect-square"
          alt={blog.title}
          src={mainImage}
        />
      </div>
      <div className="flex flex-row border-b border-grayLightV2">
        {!expanded && (
          <img
            className="hidden w-[200px] h-[200px] object-cover lg:block"
            alt={blog.title}
            src={mainImage}
          />
        )}
        <div
          id="parent"
          className="flex flex-row justify-between w-full rounded-tl-lg p-2 lg:p-6">
          <div className="flex flex-col">
            <Typography variant="body1">{blog.title}</Typography>

            <div
              style={{ height: 'inherit', overflow: 'hidden' }}
              dangerouslySetInnerHTML={{ __html: blog.description }}
            />
          </div>
        </div>
      </div>
      <div className="hidden w-full lg:block">
        {expanded && (
          <ImageList
            onClick={() => setExpanded(!expanded)}
            className="w-full min-h-[220px] object-cover"
            variant="quilted"
            cols={4}
            rowHeight={105}>
            {get(blog, 'blogsImages', []).map((img, index) => (
              <ImageListItem
                key={img.index}
                cols={index === 0 ? 2 : 1}
                rows={index === 0 ? 2 : 1}>
                {img.image && img.image !== '' ? (
                  <img
                    alt={blog.description}
                    src={`${API_URL}/file/${blog.usersId}/blogs-images/${img.image}`}
                  />
                ) : (
                  ''
                )}
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
      <div className="flex flex-row items-center justify-between border-b border-grayLightV2">
        <div className="flex flex-row py-4 justify-end w-full lg:justify-start">
          <div className="flex flex-row gap-2 items-center px-2.5 lg:px-5">
            <LocalOfferOutlinedIcon className="h-4 w-4 rotate-90" />
            {map(get(blog, 'blogsSkills', []), (skill) => (
              <Typography
                variant="subtitle2"
                key={skill.id}
                className="capitalize">
                {get(find(skillsOptions, { id: skill.skillsId }), 'name', '')}
              </Typography>
            ))}
          </div>
          <div className="flex flex-row gap-2 items-center  border-l border-grayLightV2 px-2.5 pr-5 lg:px-5">
            <QueryBuilderOutlinedIcon className="h-4 w-4" />
            <Typography
              variant="caption"
              className="text-grayDarkV3 opacity-40">
              Posted {getNameDateDiff(blog.createdAt, moment())}
            </Typography>
          </div>
          <div className="flex flex-row gap-2 items-center  border-l border-grayLightV2 px-2.5 pr-5 lg:px-5">
            <PublicIcon className="h-4 w-4" />
            <Typography variant="caption" className="text-blue opacity-40">
              <a
                target="_blank"
                rel="noopener"
                href={`${FE_URL}/blogs/${blog.slug?.toLowerCase()}?id=${
                  blog.id
                }`}>{`${FE_URL}/blogs/${blog.slug?.toLowerCase()}`}</a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
