import MainRoute from './routes';
import { ProvideGlobalState } from './store';
import { ProvideGlobalSocket } from './store/socket.io';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AlertNotification from './components/common/alerts';

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ProvideGlobalSocket>
          <ProvideGlobalState>
            <AlertNotification />
            <MainRoute />
          </ProvideGlobalState>
        </ProvideGlobalSocket>
      </LocalizationProvider>
    </div>
  );
}

export default App;
