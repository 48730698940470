import React from 'react';

// images
import LogoSymbol from '../assets/images/logo-symbol.svg';
import HeroImg from '../assets/images/home/main-hero-img.png';
import CoupleHandy from '../assets/images/home/main_img_couple_handy.png';
import CouplePaint from '../assets/images/home/main_img_couple_paint.png';
import GirlIpad from '../assets/images/home/main_img_girl_ipad.png';
import GirlLadder from '../assets/images/home/main_img_girl_ladder.png';
import ManPaint from '../assets/images/home/main_img_man_paint.png';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  HeroImg,
  CoupleHandy,
  CouplePaint,
  GirlIpad,
  GirlLadder,
  ManPaint,
];

const LoginSection = ({ children }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <section className="grid lg:grid-cols-2 min-h-screen font-roboto">
      <div className="hidden lg:block bg-gradient-to-r from-bgGradientStart to-bgGradientEnd h-screen sticky top-0">
        <img
          src={LogoSymbol}
          alt="teletradie"
          className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
        />
        
      </div>
      {children}
    </section>
  );
};

export default LoginSection;
