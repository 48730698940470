import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingComponent from './components/common/loading';
import Blogs from './pages/Blogs';
import Calls from './pages/Calls';
import ContactUs from './pages/ContactUs';
import PageNotFound from './pages/PageNotFound';
import Payments from './pages/Payments';

const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const SignIn = React.lazy(() => import('./pages/SignIn'));

const ProtectedComponent = React.lazy(() =>
  import('./components/common/protected-components'),
);
const PublicProfile = React.lazy(() => import('./pages/PublicProfile'));
const JobDetail = React.lazy(() => import('./pages/JobDetail'));
const ProfileDetail = React.lazy(() => import('./pages/Profile'));
const DashboardComponent = React.lazy(() => import('./pages/dashboard'));
const TradieProfileComponent = React.lazy(() =>
  import('./pages/TradieProfile'),
);
const DiyerProfileComponent = React.lazy(() => import('./pages/DiyerProfile'));
const JobsComponent = React.lazy(() => import('./pages/Jobs'));

export default function MainRoute() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <SignIn />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/sign-in"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <SignIn />
            </React.Suspense>
          }
        />

        <Route
          exact
          path="/forgot-password"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ForgotPassword />
            </React.Suspense>
          }
        />

        <Route
          exact
          path="/dashboard"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <DashboardComponent />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/tradie"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <TradieProfileComponent />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/diyer"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <DiyerProfileComponent />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/jobs"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <JobsComponent />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/payments"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <Payments />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/calls"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <Calls />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/blogs"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <Blogs />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/contact-us-list"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <ContactUs />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <ProfileDetail />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/profile/:id"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <PublicProfile type={1} />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/job-detail/:id"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <JobDetail />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/diyer-profile/:id"
          element={
            <React.Suspense fallback={<LoadingComponent />}>
              <ProtectedComponent>
                <PublicProfile type={2} />
              </ProtectedComponent>
            </React.Suspense>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
