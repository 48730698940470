import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    color: '#131313',
    h1: {
      fontWeight: 500,
      fontSize: 30,
      lineHeight: '35.16px',
      fontFamily: 'Roboto',
      width: 'fit-content',
    },
    h2: {
      fontSize: 26,
      lineHeight: '30.47px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      width: 'fit-content',
    },
    h3: {
      fontSize: 20,
      lineHeight: '23.44px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      width: 'fit-content',
    },
    h4: {
      fontSize: 18,
      lineHeight: '21.09px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      width: 'fit-content',
    },
    body1: {
      fontSize: 16,
      lineHeight: '18.75px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      width: 'fit-content',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Roboto',
      lineHeight: '16.41px',
      width: 'fit-content',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Roboto',
      lineHeight: '14px',
      width: 'fit-content',
    },
    caption: {
      fontSize: 10,
      fontWeight: 500,
      fontFamily: 'Roboto',
      lineHeight: '11px',
      width: 'fit-content',
    },
  },
});

export default theme;
